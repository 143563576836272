export const OPEN_INPUT = 'OPEN_INPUT';
export const OPEN_CHILD_INPUT = 'OPEN_CHILD_INPUT';
export const FLIGHT_SELECT_FROM_DATE = 'FLIGHT_SELECT_FROM_DATE';
export const FLIGHT_SELECT_TO_DATE = 'FLIGHT_SELECT_TO_DATE';
export const FLIGHT_SELECT_FROM_LOCATION = 'FLIGHT_SELECT_FROM_LOCATION';
export const FLIGHT_SELECT_TO_LOCATION = 'FLIGHT_SELECT_TO_LOCATION';
export const FLIGHT_SELECT_TRIP_TYPE = 'FLIGHT_SELECT_TRIP_TYPE';
export const FLIGHT_SELECT_PRICING_TYPE = 'FLIGHT_SELECT_PRICING_TYPE';
export const FLIGHT_SET_COUNT_ADULTS = 'FLIGHT_SET_COUNT_ADULTS';
export const FLIGHT_SET_COUNT_CHILDREN = 'FLIGHT_SET_COUNT_CHILDREN';
export const FLIGHT_ADD_HOTELS = 'FLIGHT_ADD_HOTELS';
export const SET_TOOLTIP_ERROR = 'SET_TOOLTIP_ERROR';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const TOGGLE_SHOW_ERRORS = 'TOGGLE_SHOW_ERRORS';
export const CAR_SELECT_FROM_DATE = 'CAR_SELECT_FROM_DATE';
export const CAR_SELECT_TO_DATE = 'CAR_SELECT_TO_DATE';
export const CAR_SELECT_FROM_TIME = 'CAR_SELECT_FROM_TIME';
export const CAR_SELECT_TO_TIME = 'CAR_SELECT_TO_TIME';
export const CAR_SELECT_FROM_LOCATION = 'CAR_SELECT_FROM_LOCATION';
export const CAR_SELECT_TO_LOCATION = 'CAR_SELECT_TO_LOCATION';
export const CAR_TOGGLE_RETURN_LOCATION = 'CAR_TOGGLE_RETURN_LOCATION';
export const CRUISE_SELECT_DESTINATION = 'CRUISE_SELECT_DESTINATION';
export const CRUISE_SELECT_DEPARTURE_DATE = 'CRUISE_SELECT_DEPARTURE_DATE';
export const CRUISE_SELECT_DURATION = 'CRUISE_SELECT_DURATION';
export const CRUISE_SELECT_PASSENGERS = 'CRUISE_SELECT_PASSENGERS';
export const ENABLE_VERT = 'ENABLE_VERT';
export const AGREE_COOKIE_NOTICE = 'AGREE_COOKIE_NOTICE';
export const TOGGLE_PROGRESS_LOADING = 'TOGGLE_PROGRESS_LOADING';
export const TOGGLE_AUTO_JUMP = 'TOGGLE_AUTO_JUMP';
export const HOTEL_SET_DESTINATION = 'HOTEL_SET_DESTINATION';
export const HOTEL_SET_CHECK_IN_DATE = 'HOTEL_SET_CHECK_IN_DATE';
export const HOTEL_SET_CHECK_OUT_DATE = 'HOTEL_SET_CHECK_OUT_DATE';
export const HOTEL_SET_ROOM_TYPE = 'HOTEL_SET_ROOM_TYPE';
export const HOTEL_ADD_ROOM = 'HOTEL_ADD_ROOM';
export const HOTEL_UPDATE_ROOMS = 'HOTEL_UPDATE_ROOMS';
export const HOTEL_TOGGLE_SHOW_FAMILY_OPTIONS = 'HOTEL_TOGGLE_SHOW_FAMILY_OPTIONS';
export const PASSTHRU_SET_FOUND_PARTNER_LINK = 'PASSTHRU_SET_FOUND_PARTNER_LINK';
export const PASSTHRU_SET_FOUND_PARTNER = 'PASSTHRU_SET_FOUND_PARTNER';
export const PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY = 'PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY';
export const ADMIN_RULE_SET_RULES = 'ADMIN_RULE_SET_RULES';
export const ADMIN_RULE_SET_PAGE = 'ADMIN_RULE_SET_PAGE';
export const ADMIN_RULE_SET_RULE = 'ADMIN_RULE_SET_RULE';
export const ADMIN_RULE_SET_ACTION = 'ADMIN_RULE_SET_ACTION';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const ADMIN_RULE_SET_VERTICALS_LIST = 'ADMIN_RULE_SET_VERTICALS_LIST';
export const ADMIN_RULE_SET_SITES_LIST = 'ADMIN_RULE_SET_SITES_LIST';
export const ADMIN_RULE_SET_DEVICE_TYPES_LIST = 'ADMIN_RULE_SET_DEVICE_TYPES_LIST';
export const ADMIN_RULE_SET_DEVICE_SUBTYPES_LIST = 'ADMIN_RULE_SET_DEVICE_SUBTYPES_LIST';
export const ADMIN_RULE_SET_BROWSER_TYPES_LIST = 'ADMIN_RULE_SET_BROWSER_TYPES_LIST';
export const ADMIN_RULE_SET_URL_TEMPLATES_LIST = 'ADMIN_RULE_SET_URL_TEMPLATES_LIST';
export const ADMIN_RULE_SET_URL_PARTNERS_LIST = 'ADMIN_RULE_SET_URL_PARTNERS_LIST';
export const ADMIN_RULE_SET_PER_PAGE_PAGINATION = 'ADMIN_RULE_SET_PER_PAGE_PAGINATION';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_VAPID_KEY = 'SET_VAPID_KEY';
export const SET_PRICE_ALERT = 'SET_PRICE_ALERT';
export const SET_IS_BROWSER_SUPPORT_NOTIFICATION = 'SET_IS_BROWSER_SUPPORT_NOTIFICATION';
export const SET_PUSH_SUBSCRIPTION = 'SET_PUSH_SUBSCRIPTION';
export const SET_PRICE_ALERT_EMAIL = 'SET_PRICE_ALERT_EMAIL';
export const SET_PRICE_ALERT_PHONE = 'SET_PRICE_ALERT_PHONE';
export const TOGGLE_PREVENT_ENTER = 'TOGGLE_PREVENT_ENTER';
export const TOGGLE_PREVENT_FOCUS = 'TOGGLE_PREVENT_FOCUS';
export const TOGGLE_SHOW_PHONE_NUMBER = 'TOGGLE_SHOW_PHONE_NUMBER';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_TICKET_DATA = 'SET_TICKET_DATA';
export const SET_DURATION_DETECT_REDIRECT_LINK = 'SET_DURATION_DETECT_REDIRECT_LINK';
export const TOGGLE_PRICE_ALERT_HANDLER = 'TOGGLE_PRICE_ALERT_HANDLER';
export const PRICE_ALERT_SHOW_MODAL_SIGN_UP = 'PRICE_ALERT_SHOW_MODAL_SIGN_UP';
export const PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL = 'PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL';

export const SET_COMPARE_DATA = 'SET_COMPARE_DATA';
export const SET_COMPARE_RESULT = 'SET_COMPARE_RESULT';
export const SET_FULL_SCREEN_INDEX = 'SET_FULL_SCREEN_INDEX';

export const SET_UID_IMID = 'SET_UID_IMID';
