import Helper from 'app/modules/Helper';

export const loadFrontFormDataFromSessionStorage = () => {
	try {
		const form = sessionStorage.getItem('frontForm');
		if (form) {
			return JSON.parse(form);
		}
	} catch (error) {
		console.error('Error loading form data from session storage', error);
	}
};

export const saveFrontFormDataToSessionStorage = (data) => {
	try {
		sessionStorage.setItem('frontForm', JSON.stringify(data));
	} catch (error) {
		console.error('Error saving form data to session storage', error);
	}
};

export const parseFormData = (data) => {
	const formData = new FormData();

	for (const key in data) {
		if (key.substring(0, 1) === '_') {
			const newKey = key.substring(1, key.length);
			const dataArray = data[key].split('|');
			for (let i = 0; i < dataArray.length; i++) {
				formData.append(newKey, dataArray[i]);
			}
		} else if (typeof formData.set === 'function') {
			formData.set(key, data[key]);
		} else {
			formData.append(key, data[key]);
		}
	}
	return formData;
};

export const submitHiddenForm = (data, form) => {
	for (const key in data) {
		if (key.substring(0, 1) === '_') {
			const newKey = key.substring(1, key.length);
			const dataArray = data[key].split('|');
			for (let i = 0; i < dataArray.length; i++) {
				Helper.addHiddenInputToForm(form, newKey, dataArray[i]);
			}
		} else {
			Helper.addHiddenInputToForm(form, key, data[key]);
		}
	}
	form.submit();
};
