import { add } from 'date-fns';
import {
	HOTEL_ADD_ROOM,
	HOTEL_SET_CHECK_IN_DATE,
	HOTEL_SET_CHECK_OUT_DATE,
	HOTEL_SET_DESTINATION,
	HOTEL_SET_ROOM_TYPE,
	HOTEL_TOGGLE_SHOW_FAMILY_OPTIONS,
	HOTEL_UPDATE_ROOMS,
} from '../actions/types';

const initialState = {
	hotelDestination: {},
	checkInDate: add(new Date(), { days: 7 }),
	checkOutDate: add(new Date(), { days: 14 }),
	selectedRoomType: { label: 'main.hotels.twoPerson', value: 'two-person', icon: 'icon-two-person' },
	rooms: [{ adults: 2, children: 0 }],
	random: 0,
};
const hotelReducer = (state = initialState, action) => {
	switch (action.type) {
		case HOTEL_SET_DESTINATION:
			return {
				...state,
				hotelDestination: action.payload,
			};
		case HOTEL_SET_CHECK_IN_DATE:
			return {
				...state,
				checkInDate: action.payload,
			};
		case HOTEL_SET_CHECK_OUT_DATE:
			return {
				...state,
				checkOutDate: action.payload,
			};
		case HOTEL_SET_ROOM_TYPE:
			return {
				...state,
				selectedRoomType: action.payload,
			};
		case HOTEL_TOGGLE_SHOW_FAMILY_OPTIONS:
			return {
				...state,
				showFamilyOptions: action.payload,
			};
		case HOTEL_ADD_ROOM:
			return {
				...state,
				rooms: [...state.rooms, action.payload],
				random: Math.random(),
			};
		case HOTEL_UPDATE_ROOMS:
			return {
				...state,
				rooms: action.payload,
				random: Math.random(),
			};
		default:
			return state;
	}
};

export default hotelReducer;
