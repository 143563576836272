import i18n from '../app/locales/i18n';
import Helper from '../app/modules/Helper';
import store from '../store';

export const setErrorForPriceAlertEmail = (errors) => {
	const email = store.getState().base.priceAlertEmail;
	const priceAlert = store.getState().base.priceAlert;

	if (priceAlert.length && priceAlert.indexOf('email') !== -1 && email && !Helper.isValidEmail(email)) {
		errors['price-alert-email'] = i18n.t('validation.priceAlert.email');
	}
};

export const setErrorForPriceAlertPhone = (errors) => {
	const phone = store.getState().base.priceAlertPhone;
	const priceAlert = store.getState().base.priceAlert;

	if (priceAlert.length && priceAlert.indexOf('email') !== -1 && phone && !Helper.isValidPhone(phone)) {
		errors['price-alert-phone'] = i18n.t('validation.priceAlert.phone');
	}
};
