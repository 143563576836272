export const SET_GROWTHBOOK_FEATURE_ENABLED = 'SET_GROWTHBOOK_FEATURE_ENABLED';
export const SET_GROWTHBOOK_CALLBACK = 'SET_GROWTHBOOK_CALLBACK';
export const SET_GROWTHBOOK_FEATURE_VARIANT = 'SET_GROWTHBOOK_FEATURE_VARIANT';

export const setGrowthbookFeatureEnabled =
	({ featureId, isEnabled }) =>
	(dispatch) => {
		dispatch({
			type: SET_GROWTHBOOK_FEATURE_ENABLED,
			payload: { featureId, isEnabled },
		});
	};

export const setGrowthbookCallback = (callback) => (dispatch) => {
	dispatch({
		type: SET_GROWTHBOOK_CALLBACK,
		payload: callback,
	});
};

export const setGrowthbookVariant =
	({ featureId, variant }) =>
	(dispatch) => {
		dispatch({
			type: SET_GROWTHBOOK_FEATURE_VARIANT,
			payload: { featureId, variant },
		});
	};
