import { pricingTypes, tripTypes } from 'app/modules/Constants';
import { add } from 'date-fns';
import {
	FLIGHT_ADD_HOTELS,
	FLIGHT_SELECT_FROM_DATE,
	FLIGHT_SELECT_FROM_LOCATION,
	FLIGHT_SELECT_PRICING_TYPE,
	FLIGHT_SELECT_TO_DATE,
	FLIGHT_SELECT_TO_LOCATION,
	FLIGHT_SELECT_TRIP_TYPE,
	FLIGHT_SET_COUNT_ADULTS,
	FLIGHT_SET_COUNT_CHILDREN,
} from '../actions/types';
import Helper from '../app/modules/Helper';

const initialState = {
	selectedFromDate: add(new Date(), { days: 7 }),
	selectedToDate: add(new Date(), { days: 14 }),
	selectedFromLocation: {},
	selectedToLocation: {},
	selectedPricingType: Helper.searchByValue(pricingTypes, 'economy'),
	selectedTripType: Helper.searchByValue(tripTypes, 'round-trip'),
	countAdults: 1,
	countChildren: 0,
	addHotelsChecked: false,
};
const flightReducer = (state = initialState, action) => {
	switch (action.type) {
		case FLIGHT_SELECT_FROM_DATE:
			return {
				...state,
				selectedFromDate: action.payload,
			};
		case FLIGHT_SELECT_TO_DATE:
			return {
				...state,
				selectedToDate: action.payload,
			};
		case FLIGHT_SELECT_FROM_LOCATION:
			return {
				...state,
				selectedFromLocation: action.payload,
			};
		case FLIGHT_SELECT_TO_LOCATION:
			return {
				...state,
				selectedToLocation: action.payload,
			};
		case FLIGHT_SELECT_TRIP_TYPE:
			return {
				...state,
				selectedTripType: action.payload,
			};
		case FLIGHT_SET_COUNT_CHILDREN:
			return {
				...state,
				countChildren: action.payload,
			};
		case FLIGHT_SET_COUNT_ADULTS:
			return {
				...state,
				countAdults: action.payload,
			};
		case FLIGHT_SELECT_PRICING_TYPE:
			return {
				...state,
				selectedPricingType: action.payload,
			};
		case FLIGHT_ADD_HOTELS:
			return {
				...state,
				addHotelsChecked: action.payload,
			};

		default:
			return state;
	}
};

export default flightReducer;
