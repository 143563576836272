import { BasicOption } from 'app/modules/types';
import { add } from 'date-fns';
import {
	CRUISE_SELECT_DEPARTURE_DATE,
	CRUISE_SELECT_DESTINATION,
	CRUISE_SELECT_DURATION,
	CRUISE_SELECT_PASSENGERS,
} from '../actions/types';

interface CruiseDuration extends BasicOption {
	hideOption?: boolean;
}

export interface Passengers {
	adults: number;
	children?: number;
	childrenAges: BasicOption[];
}

export const cruiseDurations = [
	/*
		Additional Any length option that is hidden in order to trigger the next input if pressing
		on the default value
	*/
	{ label: 'Any length', value: '1-365', hideOption: true },
	{ label: 'Any length', value: '1-365' },
	{ label: '1-3 Nights', value: '1-3' },
	{ label: '4-6 Nights', value: '4-6' },
	{ label: '7-9 Nights', value: '7-9' },
	{ label: '10+ Nights', value: '10-365' },
];

export interface CruiseState {
	cruiseDestination: BasicOption;
	departureDate?: Date[];
	cruiseDuration?: CruiseDuration;
	passengers: Passengers;
}

const initialState: CruiseState = {
	cruiseDestination: { value: '', label: '' },
	departureDate: [add(new Date(), { months: 3 })],
	cruiseDuration: cruiseDurations[0],
	passengers: {
		adults: 2,
		children: 0,
		childrenAges: [],
	},
};

const cruiseReducer = (state = initialState, action: { type: string; payload: unknown }) => {
	switch (action.type) {
		case CRUISE_SELECT_DEPARTURE_DATE:
			return {
				...state,
				departureDate: action.payload,
			};
		case CRUISE_SELECT_DESTINATION:
			return {
				...state,
				cruiseDestination: action.payload,
			};
		case CRUISE_SELECT_DURATION:
			return {
				...state,
				cruiseDuration: action.payload,
			};
		case CRUISE_SELECT_PASSENGERS:
			return {
				...state,
				passengers: action.payload,
			};
		default:
			return state;
	}
};

export default cruiseReducer;
