export const enginesPerLng = {
    "en": {
        "city": "zipcity-en-v2",
        "airport": "airport-en-v2",
    },
    "es": {
        "city": "zipcity-en-v2",
        "airport": "airport-en-v2",
    },
    "fr": {
        "city": "zipcity-fr",
        "airport": "airport-fr",
    },
}
