import cruiseReducer from 'reducers/cruisesReducer';
import { combineReducers } from 'redux';
import baseReducer from './baseReducer';
import carReducer from './carReducer';
import compareReducer from './compareReducer';
import flightReducer from './flightReducer';
import growthbookReducer from './growthbookReducer';
import hotelReducer from './hotelReducer';
import passthruReducer from './passthruReducer';

export default combineReducers({
	base: baseReducer,
	flight: flightReducer,
	car: carReducer,
	hotel: hotelReducer,
	passthru: passthruReducer,
	compare: compareReducer,
	growthbook: growthbookReducer,
	cruises: cruiseReducer,
});
