import animateScrollTo from 'animated-scroll-to';
import { Locale, formatISO, isAfter } from 'date-fns';
import i18n from 'i18next';
import queryString from 'query-string';
import removeAccents from 'remove-accents';
import { enginesPerLng } from '../api/elastic';
import { IAirportItem } from '../components/Common/AirportSelect/Item';
import { DEFAULT_LOCALE, localeMap } from './Constants';

class Helper {
	static isEmpty(value: any) {
		if (value === null) {
			return true;
		}
		if (typeof value === 'string' || Array.isArray(value)) {
			return value.length === 0;
		}
		if (Helper.isObject(value)) {
			return Object.keys(value).length === 0;
		}
		if (!value) {
			return true;
		}

		return false;
	}

	static isObject(obj: object) {
		return obj != null && obj.constructor.name === 'Object';
	}

	static isBoolean(variable: any) {
		return typeof variable === 'boolean';
	}

	static capitalize = (s: string) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	static startCase(s: string) {
		return s.replace(
			/\w\S*/g,
			text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
		);
	}

	static groupBy = (xs: any, key: any) => {
		// @ts-ignore
		return xs.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	static toLowerCaseKeys(obj: object) {
		let key;
		const keys = Object.keys(obj);
		let n = keys.length;
		const newobj: object = {};
		while (n--) {
			key = keys[n];
			// @ts-ignore
			newobj[key.toLowerCase()] = obj[key];
		}
		return newobj;
	}

	static parseQueryString(str: string, lowerCaseKeys?: boolean) {
		lowerCaseKeys = lowerCaseKeys || false;
		let parsed = queryString.parse(str);
		if (lowerCaseKeys) {
			// @ts-ignore
			parsed = Helper.toLowerCaseKeys(parsed);
		}
		return parsed;
	}

	static ISODateString(d: Date) {
		function pad(n: number) {
			return n < 10 ? '0' + n : n;
		}

		return (
			d.getUTCFullYear() +
			'-' +
			pad(d.getUTCMonth() + 1) +
			'-' +
			pad(d.getUTCDate()) +
			'T' +
			pad(d.getUTCHours()) +
			':' +
			pad(d.getUTCMinutes()) +
			':' +
			pad(d.getUTCSeconds()) +
			'Z'
		);
	}

	static ISODateTimeString(d: Date, t: string) {
		function pad(n: number) {
			return n < 10 ? '0' + n : n;
		}

		return `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1)}-${pad(d.getUTCDate())}T${t}:00Z`;
	}

	static isNumeric(n: any) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	static chunk = (arr: Array<any>, size: number) =>
		Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

	static getElementY(query: string) {
		const element = document.querySelector(query);
		return element ? window.scrollY + element.getBoundingClientRect().top : 0;
	}

	static doScrolling(element: string, duration: number) {
		const domElement = document.querySelector(element);

		if (domElement) {
			animateScrollTo(domElement, {
				minDuration: duration,
			});
		}
		return;
	}

	static addHiddenInputToForm(form: HTMLFormElement, name: string, value: any) {
		if (form) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = name;
			input.setAttribute('value', Helper.removeAccentsHelper(value));
			form.appendChild(input);
		}
	}

	static serialize = function (obj: object) {
		const str = [];

		for (const p in obj) {
			if (obj.hasOwnProperty(p)) {
				// @ts-ignore
				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
			}
		}

		return str.join('&');
	};

	static isIsoDate(str: string) {
		if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
		const d = new Date(str);
		return d.toISOString() === str;
	}

	static replaceAll(target: string, search: string, replacement: string) {
		try {
			const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
			return target.replace(new RegExp(escapedSearch, 'g'), replacement);
		} catch (error) {
			console.error('Error in replaceAll:', error);
			return target;
		}
	}
	static isValidEmail(email: string) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	static isValidPhone(phone: string) {
		if (!phone) return true;

		// Regular expressions for US, CA, AU, and UK phone numbers
		const usRegex = /^(\+?1)?[0-9]\d{9}$/;
		const caRegex = /^(\+?1)?[0-9]\d{9}$/;
		const auRegex = /^(\+?61|0)[0-9](?!0)\d{8}$/;
		const ukRegex = /^(\+?44|0)[0-9]\d{9}$/;

		// Test the phone number against each regular expression
		const res = usRegex.test(phone) || caRegex.test(phone) || auRegex.test(phone) || ukRegex.test(phone);

		return res;
	}

	static trans(key: string | string[], keys = {}) {
		return i18n.t(key, keys) as string;
	}

	static bool(string: string) {
		string = string && string.toLowerCase();
		return string === 'true';
	}

	static getOrigin(url: string) {
		if (/^\/\//.test(url)) {
			// no scheme, use current scheme, extract domain
			url = window.location.protocol + url;
		} else if (/^\//.test(url)) {
			// just path, use whole origin
			url = window.location.origin + url;
		}
		// @ts-ignore
		return url.match(/^([^/]+\/\/[^/]+)/)[0];
	}

	static isDateAfter(date: Date) {
		return isAfter(new Date(), date);
	}

	static isValidDate(date: any) {
		return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
	}

	static searchByValue = (items: Array<any>, value: string) => {
		for (let i = 0; i < items.length; i++) {
			if (value === items[i].value) {
				return items[i];
			}
		}
		return {};
	};

	static isValidTime(time: string) {
		const pattern = /^\d{2,}:\d{2}$/;
		return pattern.test(time);
	}

	static getBaseUrl(url: string) {
		if (url) {
			const parts = url.split('://');

			if (parts.length > 1) {
				return parts[0] + '://' + parts[1].split('/')[0];
			} else {
				return parts[0].split('/')[0];
			}
		}
	}

	static isFromEU(countryCode: string) {
		const europeCountries = [
			'AT',
			'IT',
			'BE',
			'LV',
			'BG',
			'LT',
			'HR',
			'LU',
			'CY',
			'MT',
			'CZ',
			'NL',
			'DK',
			'PL',
			'EE',
			'PT',
			'FI',
			'RO',
			'FR',
			'SK',
			'DE',
			'SI',
			'GR',
			'ES',
			'HU',
			'SE',
			'IE',
			'GB',
		];
		return europeCountries.indexOf(countryCode) !== -1;
	}

	static convertVapidKey(base64String: string) {
		const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
		const rawData = window.atob(base64);
		// @ts-ignore
		return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
	}

	static getQueryString() {
		let search = window.location.search;
		if (!search) {
			return '';
		}
		if (search.substring(0, 1) === '/') {
			search = search.substring(1, search.length);
		}
		return search;
	}

	static getLink(path: string, queryObject?: object) {
		let queryStringS = '';
		if (typeof window != 'undefined') {
			queryStringS = Helper.getQueryString();
		} else if (!Helper.isEmpty(queryObject)) {
			// @ts-ignore
			queryStringS = '?' + queryString.stringify(queryObject);
		}

		return path + queryStringS;
	}

	static hasPT(number: number) {
		return Helper.checkQueryStringKeyValue('pt', number.toString());
	}

	static checkQueryStringKeyValue(key: string | number, value: string | string[]) {
		if (typeof window !== 'undefined') {
			const data = Helper.parseQueryString(window.location.search, true);

			if (Array.isArray(value)) {
				return data.hasOwnProperty(key) && value.indexOf(data[key] as string) !== -1;
			}
			return data.hasOwnProperty(key) && data[key] === value;
		}
	}

	static isValidDateFormat(dateString: string) {
		const regEx = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateString.match(regEx)) return false; // Invalid format
		const d = new Date(dateString);
		const dNum = d.getTime();
		if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
		return d.toISOString().slice(0, 10) === dateString;
	}

	static isValidTimeFormat(dateString: string) {
		const regEx = /^\d{2}:00$/;
		return dateString.match(regEx);
	}

	static convertStringDateToDateObject(dateString: string) {
		const dateStringA = dateString.split('-');
		// @ts-ignore
		return new Date(dateStringA[0], parseInt(dateStringA[1]) - 1, dateStringA[2]);
	}

	static appendLocationNameDependingOnVertical(array: any[], selected: IAirportItem, vertical?: string) {
		// guys, I am very sorry for that...
		if (vertical === 'hotels') {
			if (selected.name) {
				array.push(selected.name);
			} else if (selected.city) {
				array.push(selected.city);
			}
		} else if (selected.city) {
			array.push(selected.city);
		} else if (selected.name) {
			array.push(selected.name);
		}
	}

	static getSelectedLocation(selected: IAirportItem, width: number, vertical?: string) {
		let selectedItems: (string | undefined)[] = [];
		if (selected.clicktripzHotelId) {
			selectedItems.push(selected.name);
			selectedItems.push(selected.city);
			return selectedItems.join(', ');
		}
		this.appendLocationNameDependingOnVertical(selectedItems, selected, vertical);
		if (selected.state) {
			selectedItems.push(selected.state);
		}
		if (selected.country) {
			selectedItems.push(selected.country);
		}
		let selectedItemsString = selectedItems.join(', ');
		if (selected.iata) {
			selectedItemsString = selectedItemsString + ` (${selected.iata}) `;
		}
		const countChar = selectedItemsString.length;
		if (countChar * 6 > width) {
			selectedItems = [];
			this.appendLocationNameDependingOnVertical(selectedItems, selected, vertical);
			if (vertical === 'hotels') {
				if (selected.country) {
					selectedItems.push(selected.country);
				}
			}
			selectedItemsString = selectedItems.join(', ');
			if (selected.iata) {
				selectedItemsString = selectedItemsString + ` (${selected.iata}) `;
			}
		}
		return selectedItemsString;
	}

	static convertSlugToName(slug: string) {
		return slug
			.split('-')
			.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
			.join(' ');
	}

	static getDiffDaysBetweenTwoDates(date1: Date, date2: Date): number {
		// @ts-ignore
		const diffTime = Math.abs(date2 - date1);
		return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	}

	static convertElasticCityResultItemToAirportItem(item: any): IAirportItem {
		let type = '';

		if (item._meta.engine.includes('city')) {
			type = 'city';
		} else {
			type = 'hotels';
		}

		return {
			country: item.country.raw,
			name: item.name ? item.name.raw : item.city?.raw,
			city: item.city.raw,
			state: item.state.raw,
			documentId: item._meta.id,
			cityID: item?.city_id?.raw,
			type,
		};
	}

	static hasNumber(num: any): boolean {
		const hasNumber = /\d/;
		return hasNumber.test(num);
	}

	static getEngineName(type: string, lng: string): string {
		if (!enginesPerLng.hasOwnProperty(lng)) {
			lng = 'en';
		}
		// @ts-ignore
		return enginesPerLng[lng][type];
	}
	static removeAccentsHelper(value: any) {
		return typeof value == 'string' ? removeAccents(value) : value;
	}

	static comparePlainObjects(obj1: any, obj2: any) {
		// eslint-disable-next-line eqeqeq
		if (obj1 == obj2) {
			return true;
		}
		if (typeof obj1 != 'object' || typeof obj2 != 'object') {
			return false;
		}
		if (Object.keys(obj1).length !== Object.keys(obj2).length) {
			return false;
		}
		for (const key in obj1) {
			if (!obj2.hasOwnProperty(key)) {
				return false;
			}
			if (obj1[key] !== obj2[key]) {
				return false;
			}
		}
		return true;
	}

	static makeUniqueArray(array: any[], condition: any) {
		const uniqueArray: any[] = [];
		array.forEach((item) => {
			const r = uniqueArray.find(condition(item));
			if (!r) {
				uniqueArray.push(item);
			}
		});
		return uniqueArray;
	}

	static getQueryObject() {
		if (typeof window === 'undefined') return {};

		return Helper.parseQueryString(window.location.search, true);
	}

	static isPa() {
		const data = this.getQueryObject();
		return data.hasOwnProperty('pa') && data['pa'] === '1';
	}

	static isSa() {
		const data = this.getQueryObject();
		return data.hasOwnProperty('sa') && data['sa'] === '1';
	}

	static isRtlLanguage(lng: string) {
		return ['ar', 'he'].includes(lng);
	}

	static isArabicLanguage(lng: string) {
		return ['ar'].includes(lng);
	}

	static urlContainsSearchParams() {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.toString() !== '';
	}

	static getLocaleByCode = (code: string): Locale => {
		return code === 'en' ? localeMap[DEFAULT_LOCALE] : localeMap[code];
	};

	static getCurrentLocale = (lng: string) => {
		return lng === 'en' ? DEFAULT_LOCALE : lng;
	};

	static toISODateTimeString(date: Date, hour = '00:00') {
		const [hours, minutes] = hour.split(':').map(Number);

		const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);

		return formatISO(newDate);
	}

	static isMobile = () => {
		const width = window.innerWidth;
		return width < 575;
	};

	static isDesktop = () => {
		const width = window.innerWidth;
		return width > 1200;
	};

	static setOrAppendParam(url: URL, param: string, value: string) {
		if (url.searchParams.has(param)) {
			url.searchParams.set(param, value);
		} else {
			url.searchParams.append(param, value);
		}
	}
	// Replaces obj.hasOwnProperty(prop) because it's not entirely safe to use it
	static hasProperty(obj: any, prop: string) {
		return Object.prototype.hasOwnProperty.call(obj, prop);
	}

	static removeParam(url: URL, param: string) {
		if (url.searchParams.has(param)) {
			url.searchParams.delete(param);
		}
	}
}

export default Helper;
